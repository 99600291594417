import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Button, Tabs, Tab, Box } from "@mui/material";
import Interesse from "./Interesse";
import VisitaTecnica from "./VisitaTecnica";
import Diagnostico from "./Diagnostico";
import Financiamento from "./Financiamento";
import CadastroProjeto from "./CadastroProjeto";
import Implementacao from "./Implementacao";
import { useStoreState, useStoreActions } from "easy-peasy";
import PropTypes from "prop-types";
import api from "../../services/api";
import { useParams } from "react-router-dom";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Processo = () => {
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  let params = useParams();
  let currProcesso = useStoreState((state) => state.processo.current);
  let selectProcesso = useStoreActions((actions) => actions.processo.select);
  const [ready, setReady] = React.useState(false);
  const [estagio, setEstagio] = React.useState(0);
  const [estagios, setEstagios] = React.useState(null);
  const handleChange = (event, newValue) => {
    setEstagio(newValue);
  };

  function a11yProps(index) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  const tabEnabledEstagio = {
    preDiagnostico: ["inicioAprovado", "preDiagReprovado", "preDiagIniciado", "preDiagAprovado", "encSemImplementacao", "diagIniciado", "preDiagAprovado", "diagIniciado", "apreCliente", "diagAprovadoIF","finIniciado", "finAprovado", "finRecProprio", "financiadoBOT", "projetoCadastrado", "diagAprovadoIFPA", "projetoIniciado", "emImplementacao", "confPrevRP", "confPrevBOT", "confPrevIf", "abaiPrevRP", "abaiPrevIf", "abaiPrevBOT"],
    diagnosticoEnergetico: ["preDiagAprovado", "diagIniciado", "apreCliente", "encSemImplementacao", "diagAprovadoIF", "finIniciado", "finAprovado", "finRecProprio", "financiadoBOT", "projetoCadastrado", "diagAprovadoIFPA", "projetoIniciado", "emImplementacao", "confPrevRP", "confPrevBOT", "confPrevIf", "abaiPrevRP", "abaiPrevIf", "abaiPrevBOT"],
    financiamento: ["diagAprovadoIF", "finIniciado", "finAprovado", "abaiPrevIf", "projetoCadastrado", "projetoIniciado", "confPrevIf", "emImplementacao"],
    cadastroProjeto: ["finAprovado", "finRecProprio", "financiadoBOT", "projetoCadastrado", "projetoIniciado", "emImplementacao", "confPrevRP", "confPrevBOT", "confPrevIf", "abaiPrevRP", "abaiPrevIf", "abaiPrevBOT"],
    implementacao: ["projetoCadastrado", "emImplementacao", "confPrevRP", "confPrevBOT", "confPrevIf", "abaiPrevRP", "abaiPrevIf", "abaiPrevBOT"]
  };

  const tabEnabledRole = {
    gestorGIZ: [
      "preDiagnostico",
      "diagnosticoEnergetico",
      "financiamento",
      "cadastroProjeto",
      "implementacao",
    ],
    superAdmin: [
      "preDiagnostico",
      "preDiagReprovado",
      "diagnosticoEnergetico",
      "financiamento",
      "cadastroProjeto",
      "implementacao",
    ],
    parceiro: ["preDiagnostico"],
    desenvolveSP: ["financiamento", "cadastroProjeto", "implementacao"],
    consultorEE: [
      "preDiagnostico",
      "diagnosticoEnergetico",
      "financiamento",
      "cadastroProjeto",
      "implementacao",
    ],
    gestorGarantidor: [
      "diagnosticoEnergetico",
      "financiamento",
      "cadastroProjeto",
      "implementacao",
    ],
    validadorIndSENAI: [
      "preDiagnostico",
      "diagnosticoEnergetico",
      "cadastroProjeto",
      "implementacao",
    ],
    validadorIndGIZ: [
      "diagnosticoEnergetico",
      "financiamento",
      "cadastroProjeto",
      "implementacao",
    ],
    instFin: ["financiamento", "cadastroProjeto", "implementacao"],
    gestorSENAI: [
      "preDiagnostico",
      "diagnosticoEnergetico",
      "cadastroProjeto",
      "implementacao",
    ],
  };

  let user = useStoreState((state) => state.auth.user);

  const disabledTab = (tab) => {
    if (!currProcesso || !estagios || !tabEnabledRole[user.roleId].includes(tab)) {
      return true;
    } else {
      if (tabEnabledEstagio[tab].includes(currProcesso["estagio"])) {
        return false;
      }
      return true;
    }
  };

  React.useEffect(async () => {
    startLoading();
    try {
      setEstagios(
        (await api.http.get("/estagios")).data.reduce((map, estagio) => {
          map[estagio.id] = estagio;
          return map;
        }, {})
      );
      if (params.id) {
        //console.log("Processo get by id : ",(await api.http.get("/processos/" + params.id)).data);
        selectProcesso((await api.http.get("/processos/" + params.id)).data);
      } else {
        selectProcesso(null);
      }
      stopLoading();
      setReady(true);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  }, [params]);

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={estagio}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          onChange={handleChange}
        >
          <Tab label="Cadastro de elegíveis" {...a11yProps(0)} />
          <Tab
            label="Pré-diagnóstico energético"
            disabled={disabledTab("preDiagnostico")}
            {...a11yProps(1)}
          />
          <Tab
            label="Diagnóstico energético"
            disabled={disabledTab("diagnosticoEnergetico")}
            {...a11yProps(2)}
          />
          <Tab
            label="Financiamento"
            disabled={
              disabledTab("financiamento") ||
              estagios[currProcesso["estagio"]].id === "finRecProprio" ||
              estagios[currProcesso["estagio"]].id === "encSemImplementacao" ||
              estagios[currProcesso["estagio"]].id === "financiadoBOT" ||
              (currProcesso &&
                currProcesso.diagnostico &&
                ["B", "R", "N"].includes(
                  currProcesso.diagnostico.modalidadeFinanciamento
                ))
            }
            {...a11yProps(3)}
          />
          <Tab
            label="Cadastro do projeto"
            disabled={
              disabledTab("cadastroProjeto") ||
              estagios[currProcesso["estagio"]].id === "encSemImplementacao"
            }
            {...a11yProps(4)}
          />
          <Tab
            label="Implementação"
            disabled={disabledTab("implementacao")}
            {...a11yProps(5)}
          />
        </Tabs>
      </Box>

      <TabPanel value={estagio} index={0} className="tabpanel">
        {ready && <Interesse />}
      </TabPanel>
      <TabPanel value={estagio} index={1} className="tabpanel">
        <VisitaTecnica />
      </TabPanel>
      <TabPanel value={estagio} index={2} className="tabpanel">
        <Diagnostico />
      </TabPanel>
      <TabPanel value={estagio} index={3} className="tabpanel">
        <Financiamento />
      </TabPanel>
      <TabPanel value={estagio} index={4} className="tabpanel">
        <CadastroProjeto />
      </TabPanel>
      <TabPanel value={estagio} index={5} className="tabpanel">
        <Implementacao />
      </TabPanel>
    </div>
  );
};

export default Processo;

import { createStore } from 'easy-peasy';

import authStore from './authStore';
import interesseStore from './interesseStore';
import navStore from './navStore';
import processoStore from './processoStore';


const store = createStore({
  interesse: interesseStore,
  auth: authStore,
  nav: navStore,
  processo: processoStore
});

export default store;

import {TextField as MUITextField}  from '@mui/material';
import { Controller } from "react-hook-form";

const TextField = ({field,useFormRef,schema,...props}) => {
  const {control} = useFormRef;
  return (
    <Controller
      name={field}
      control={control}
      render={({
        field,
        fieldState: { invalid, isTouched, isDirty, error }
      }) => (
        <MUITextField
          label={schema?.fields[field.name].spec.label}
          required={schema?.fields[field.name].exclusiveTests['required']}
          error={error!=null}
          helperText={error?.message}
          {...field}
          value={field.value?field.value:''}
          {...props} />)}
      defaultValue=''
    />

  );
};

export default TextField;

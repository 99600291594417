import React, { useState } from "react";
import api from "../../services/api";

import { Divider, Grid } from "@mui/material";
import TaxaConversaoWidget from "../../components/TaxaConversaoWidget";
import FunilWidget from "../../components/FunilWidget";

const Estagios = () => {
  React.useEffect(() => {
    let mount = {
      isMounted: true,
    };
    loadIndGerais(mount);
  }, []);

  const [indEstagios, setIndEstagios] = React.useState(null);

  const loadIndGerais = async (mount) => {
    let getIndEstagios = await api.http.get("/relatorios/estagios");
    if (mount.isMounted) {
      setIndEstagios(getIndEstagios.data);
    }
  };

  const computePercent = (value, total) => {
    if (!typeof value === "number") {
      return 0;
    }
    if (!typeof total === "number" || total === 0) {
      return 0;
    }
    return (value * 100.0) / total;
  };

  return (
    <Grid container spacing={2} flex justifyContent={"center"}>
      <Divider>
        <h3>Distribuição Atual de Processos Por Estágio</h3>
      </Divider>
      <Grid
        container
        direction="row"
        justifyContent="center"
        wrap="wrap"
        paddingX={"18px"}
        paddingY={"12px"}
      >
        {indEstagios != null && indEstagios.totais != null && (
          <React.Fragment>
            <TaxaConversaoWidget
              title={"Cadastro de Elegíveis"}
              subtitle={"Total de Cadastros"}
              value={indEstagios.totais.cadastroDeElegiveisDistribuicao}
            />
            <TaxaConversaoWidget
              title={"Pré-Diagnóstico Energético"}
              subtitle={
                "Em Pré-Diagnóstico: " +
                indEstagios.totais.preDiagnosticoEnergeticoDistribuicao
              }
              value={computePercent(
                indEstagios.totais.preDiagnosticoEnergeticoDistribuicao,
                indEstagios.totais.totalDistribuicao
              )}
              isPercent
            />
            <TaxaConversaoWidget
              title={"Diagnóstico Energético"}
              subtitle={
                "Em Diagnóstico Energético: " +
                indEstagios.totais.diagnosticoEnergeticoDistribuicao
              }
              value={computePercent(
                indEstagios.totais.diagnosticoEnergeticoDistribuicao,
                indEstagios.totais.totalDistribuicao
              )}
              isPercent
            />
            <TaxaConversaoWidget
              title={"Financiamento"}
              subtitle={"Em Financiamento: " + indEstagios.totais.financiamentoDistribuicao}
              value={computePercent(
                indEstagios.totais.financiamentoDistribuicao,
                indEstagios.totais.totalDistribuicao
              )}
              isPercent
            />
            <TaxaConversaoWidget
              title={"Cadastro do Projeto"}
              subtitle={
                "Em Cadastro do Projeto: " + indEstagios.totais.cadastroProjeto
              }
              value={computePercent(
                indEstagios.totais.cadastroProjeto,
                indEstagios.totais.totalDistribuicao
              )}
              isPercent
            />
            <TaxaConversaoWidget
              title={"Implementação"}
              subtitle={"Em Implementação: " + indEstagios.totais.implementacao}
              value={computePercent(
                indEstagios.totais.implementacao,
                indEstagios.totais.totalDistribuicao
              )}
              isPercent
            />
            <TaxaConversaoWidget
              title={"Finalizados"}
              subtitle={"Finalizados: " + indEstagios.totais.finalizado}
              value={computePercent(
                indEstagios.totais.finalizado,
                indEstagios.totais.totalDistribuicao
              )}
              isPercent
            />
            <TaxaConversaoWidget
              title={"Total"}
              subtitle={"Total de Processos"}
              value={indEstagios.totais.totalDistribuicao}
            />
          </React.Fragment>
        )}
      </Grid>
      <Divider>
        <h3>Conversão de Processos Por Estágio</h3>
      </Divider>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems={"center"}
        wrap="wrap"
        paddingX={"18px"}
        paddingY={"12px"}
      >
        {indEstagios != null && indEstagios.totais != null && (
          <React.Fragment>
            <FunilWidget
              title={"Processos Iniciados"}
              abosluteValue={indEstagios.totais.totalConversao}
              variationValue={100}
              color={"#61bb46"}
              width={"100%"}
            />
            <FunilWidget
              title={"Pré-Diagnóstico Energético"}
              abosluteValue={
                indEstagios.totais.preDiagnosticoEnergeticoConversao +
                indEstagios.totais.diagnosticoEnergeticoConversao +
                indEstagios.totais.interesseImplementar +
                indEstagios.totais.financiamentoConversao +
                indEstagios.totais.cadastroProjeto +
                indEstagios.totais.implementacao +
                indEstagios.totais.finalizado
              }
              variationValue={computePercent(
                indEstagios.totais.preDiagnosticoEnergeticoConversao +
                indEstagios.totais.diagnosticoEnergeticoConversao +
                indEstagios.totais.interesseImplementar +
                indEstagios.totais.financiamentoConversao +
                indEstagios.totais.cadastroProjeto +
                indEstagios.totais.implementacao +
                indEstagios.totais.finalizado,
                indEstagios.totais.totalConversao
              )}
              color={"#61bb46"}
              width={"95%"}
            />
            <FunilWidget
              title={"Diagnóstico Energético"}
              abosluteValue={
                indEstagios.totais.diagnosticoEnergeticoConversao +
                indEstagios.totais.interesseImplementar +
                indEstagios.totais.financiamentoConversao +
                indEstagios.totais.cadastroProjeto +
                indEstagios.totais.implementacao +
                indEstagios.totais.finalizado
              }
              variationValue={computePercent(
                indEstagios.totais.diagnosticoEnergeticoConversao +
                indEstagios.totais.financiamentoConversao +
                indEstagios.totais.interesseImplementar +
                indEstagios.totais.cadastroProjeto +
                indEstagios.totais.implementacao +
                indEstagios.totais.finalizado,
                indEstagios.totais.preDiagnosticoEnergeticoConversao +
                indEstagios.totais.diagnosticoEnergeticoConversao +
                indEstagios.totais.interesseImplementar +
                indEstagios.totais.financiamentoConversao +
                indEstagios.totais.cadastroProjeto +
                indEstagios.totais.implementacao +
                indEstagios.totais.finalizado
              )}
              color={"#61bb46"}
              width={"90%"}
            />
            <FunilWidget
              title={"Interesse em implementar"}
              abosluteValue={
                indEstagios.totais.interesseImplementar +
                indEstagios.totais.financiamentoConversao +
                indEstagios.totais.cadastroProjeto +
                indEstagios.totais.implementacao +
                indEstagios.totais.finalizado
              }
              variationValue={computePercent(
                indEstagios.totais.interesseImplementar +
                indEstagios.totais.financiamentoConversao +
                indEstagios.totais.cadastroProjeto +
                indEstagios.totais.implementacao +
                indEstagios.totais.finalizado,
                indEstagios.totais.diagnosticoEnergeticoConversao +
                indEstagios.totais.interesseImplementar +
                indEstagios.totais.financiamentoConversao +
                indEstagios.totais.cadastroProjeto +
                indEstagios.totais.implementacao +
                indEstagios.totais.finalizado
              )}
              color={"#61bb46"}
              width={"85%"}
              tooltipText={`Financiamento: ${indEstagios.totais.financiamentoTotip}`}
            />
            <FunilWidget
              title={"Cadastro do Projeto"}
              abosluteValue={
                indEstagios.totais.cadastroProjeto +
                indEstagios.totais.implementacao +
                indEstagios.totais.finalizado
              }
              variationValue={computePercent(
                indEstagios.totais.cadastroProjeto +
                indEstagios.totais.implementacao +
                indEstagios.totais.finalizado,
                indEstagios.totais.interesseImplementar +
                indEstagios.totais.financiamentoConversao +
                indEstagios.totais.cadastroProjeto +
                indEstagios.totais.implementacao +
                indEstagios.totais.finalizado
              )}
              color={"#61bb46"}
              width={"80%"}
              tooltipText={`SENAI: ${indEstagios.totais.implementacaoSenai}`}
            />
            <FunilWidget
              title={"Implementação"}
              abosluteValue={
                indEstagios.totais.implementacao +
                indEstagios.totais.finalizado
              }
              variationValue={computePercent(
                indEstagios.totais.implementacao +
                indEstagios.totais.finalizado,
                indEstagios.totais.cadastroProjeto +
                indEstagios.totais.implementacao +
                indEstagios.totais.finalizado
              )}
              color={"#61bb46"}
              width={"75%"}
            />
            <FunilWidget
              title={"Finalizados"}
              abosluteValue={
                indEstagios.totais.finalizado
              }
              variationValue={computePercent(
                indEstagios.totais.finalizado,
                indEstagios.totais.finalizado +
                indEstagios.totais.implementacao
              )}
              color={"#61bb46"}
              width={"70%"}
              hideTriangle
            />
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
};

export default Estagios;

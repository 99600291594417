import * as React from "react";
import { Grid, FormControl, Divider, Checkbox, } from "@mui/material";
import {
  Select,
  SelectUF,
  SelectCidade,
  TextField,
  CNPJField,
  CEPField,
  FloatField,
  TelField,
} from "./";
import { regioes, listaComoSoubePrograma, operaFeriados } from "../static";
import api from "../services/api";
import { useStoreState, useStoreActions } from "easy-peasy";
import cep from "cep-promise";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Autocomplete, DateField } from "../components";

const CamposProcessoInicial = ({
  useFormRef,
  schema,
  disabled,
  processo,
  showRegiao,
}) => {
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  let loading = useStoreState((state) => state.nav.loading);
  const [setoresAtuacao, setSetoresAtuacao] = React.useState(null);
  const { watch, setValue, resetField, getFieldState } = useFormRef;

  const watchEstado = watch("estado");

  const [states, setStates] = React.useState([]);
 //const [selectedState, setSelectedState] = React.useState('');
  const [cities, setCities] = React.useState([]);

  const cepField = watch("cep");

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const regimesfuncionamentos = [
    {
      id: "seg",
      name: "Segunda-Feira",
    },
    {
      id: "ter",
      name: "Terça-Feira",
    },
    {
      id: "qua",
      name: "Quarta-Feira",
    },
    {
      id: "qui",
      name: "Quinta-Feira",
    },
    {
      id: "sex",
      name: "Sexta-Feira",
    },
    {
      id: "sab",
      name: "Sábado",
    },
    {
      id: "dom",
      name: "Domingo",
    },
  ];  

  const loadSetoresAtuacao = async (mount) => {
    startLoading();
    try {
     // console.log("setores-atuacao");
      let data = (await api.http.get("/setores-atuacao")).data;
      if (mount.isMounted) setSetoresAtuacao(data);
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  React.useEffect(() => {
    let mount = {
      isMounted: true,
    };
    loadSetoresAtuacao(mount);
    return () => {
      mount.isMounted = false;
    };
  }, []);

  React.useEffect(()=>{
     ///recuperar dados da api ibge e preencher o dropdow estados
     async function fetchStates() {
      try {
        const response = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados');
        const data = await response.json();
        setStates(data);     

      } catch (error) {
        console.error('Error fetching states:', error);
      }
    }

    fetchStates();
  }, []);

  React.useEffect(() => {
    async function fetchCities() {
      if (watchEstado) {
        try {
          const response = await fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${watchEstado}/municipios`);
          const data = await response.json();
          setCities(data);
        } catch (error) {
          console.error('Error fetching cities:', error);
        }
      } else {
        setCities([]);
      }
    }

    fetchCities();
  }, [watchEstado]);

   //console.log("Campos Processo Inicial - Interese", processo);

  const loadCEP = async (mount) => {
    if (cepField && cepField.length == 9 && getFieldState("cep").isDirty) {
      startLoading();
      try {
        const cepResp = await cep(cepField);
        if (mount.isMounted) {
          setValue("rua", cepResp.street);
          setValue("bairro", cepResp.neighborhood);
          setValue("cidade", cepResp.city);
          setValue("estado", cepResp.state);
        }
        stopLoading();
      } catch (error) {
        stopLoading({
          message: error.message,
          severity: "error",
        });
      }
    }
  };

  React.useEffect(async () => {
    let mount = {
      isMounted: true,
    };
    loadCEP(mount);
    return () => {
      mount.isMounted = false;
    };
  }, [cepField]);

  //console.log("Campos novos useforref ", useFormRef);

  return (
    <React.Fragment>
      <Divider>
        <h3>Dados da empresa</h3>
      </Divider>
      <FormControl sx={{ my: 2 }} fullWidth>
        <CNPJField
          field="cnpj"
          disabled={disabled}
          useFormRef={useFormRef}
          schema={schema}
        />
      </FormControl>
      <FormControl sx={{ my: 2 }} fullWidth>
        <TextField
          field="razaoSocial"
          disabled={disabled}
          useFormRef={useFormRef}
          schema={schema}
        />
      </FormControl>
      <Grid container spacing={2} fullWidth justifyContent={"center"}>
        <Grid item xs={6}>
          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              field="numFuncs"
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              type="text"
              disabled={disabled}
              useFormRef={useFormRef}
              schema={schema}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl sx={{ my: 2 }} fullWidth>
            <FloatField
              field="valMedioFatEnergia"
              disabled={disabled}
              useFormRef={useFormRef}
              schema={schema}
            />
          </FormControl>
        </Grid>
      </Grid>

      <FormControl sx={{ my: 2 }} fullWidth>
        {setoresAtuacao && (
          <Select
            field="setorAtuacaoId"
            useFormRef={useFormRef}
            schema={schema}
            options={setoresAtuacao}
            getOptionLabel={(option) => option.nome}
            disabled={disabled}
          />
        )}
      </FormControl>

      <FormControl sx={{ my: 2 }} fullWidth>
        <CEPField
          field="cep"
          disabled={disabled}
          useFormRef={useFormRef}
          schema={schema}
        />
      </FormControl>

      <Grid container spacing={2}>
        <Grid item xs={8}>
          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              field="rua"
              disabled={disabled}
              useFormRef={useFormRef}
              schema={schema}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              field="numero"
              disabled={disabled}
              useFormRef={useFormRef}
              schema={schema}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              field="complemento"
              disabled={disabled}
              useFormRef={useFormRef}
              schema={schema}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              field="bairro"
              disabled={disabled}
              useFormRef={useFormRef}
              schema={schema}
            />
          </FormControl>
        </Grid>        
        <Grid item xs={4}>
          <FormControl sx={{ my: 2 }} fullWidth>            
            <SelectUF
            field="estado"
            useFormRef={useFormRef}
            schema={schema}
            options={states}
            getOptionLabel={(option) => option.nome}
          />         
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ my: 2 }} fullWidth>
             <SelectCidade
              field="cidade"
              useFormRef={useFormRef}
              schema={schema}
              options={cities}
              getOptionLabel={(option) => option.nome}
            />
          </FormControl>
        </Grid>
      </Grid>
      
      {showRegiao && (
        <FormControl sx={{ my: 2 }} fullWidth>
          <Select
            field="regiaoId"
            useFormRef={useFormRef}
            schema={schema}
            options={regioes}
            disabled={disabled}
          />
        </FormControl>
      )}

      <Divider>
        <h3>Dados do responsável legal</h3>
      </Divider>

      <FormControl sx={{ my: 2 }} fullWidth>
        <TextField
          field="nome"
          disabled={disabled}
          useFormRef={useFormRef}
          schema={schema}
        />
      </FormControl>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl sx={{ my: 2 }} fullWidth>
            <TelField
              field="telefone"
              disabled={disabled}
              useFormRef={useFormRef}
              schema={schema}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl sx={{ my: 2 }} fullWidth>
            <TextField
              field="email"
              type="email"
              disabled={disabled}
              useFormRef={useFormRef}
              schema={schema}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Divider>
        <h3>Outros</h3>
      </Divider>
      <FormControl sx={{ my: 2 }} fullWidth>
        <Select
          field="comoSoubeProgramaId"
          useFormRef={useFormRef}
          schema={schema}
          options={listaComoSoubePrograma}
          disabled={disabled}
        />
      </FormControl>
    </React.Fragment>
  );
};

export default CamposProcessoInicial;

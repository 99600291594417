import React, { useMemo, useState } from "react";
import {
  Grid,
  FormControl,
  Divider,
  Button,
  Box,
  Paper,
  TextField,
  DialogContentText,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { pt } from "yup-locale-pt";
import {
  Checkbox,
  Select,
  DialogoConfirmacao,
  DialogoConfirmacaoSimples,
  FileField,
  FileListField,
  Timestamps,
} from "../../components";
import { useStoreState, useStoreActions } from "easy-peasy";
import api from "../../services/api";

yup.setLocale(pt);

const VisitaTecnica = () => {
  let user = useStoreState((state) => state.auth.user);
  let currProcesso = useStoreState((state) => state.processo.current);
  let selectProcesso = useStoreActions((actions) => actions.processo.select);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [listaTecnicos, setListaTecnicos] = React.useState([]);
  const [visitaTecnica, setVisitaTecnica] = React.useState(null);

  const schema = yup
    .object({
      tecnicoId: yup.number().positive().label("Escolha um técnico").required(),
      preDiagnosticoId: yup
        .number()
        .positive()
        .nullable()
        .label("Pré-diagnóstico"),
      pendenciasId: yup.number().positive().nullable().label("Pendências"),
      dreId: yup.number().nullable().label("DRE mais recente"),
      balancoId: yup.number().nullable().label("Balanço mais recente"),
      docsTecnicosField: yup
        .array()
        .label("Arraste documentos técnicos aqui ou clique"),
      faturasEnergiaField: yup
        .array()
        .label("Arraste 12 últimas faturas de energia elétrica"),
      faturasEnergeticosField: yup
        .array()
        .label("Arraste as faturas dos energéticos"),
      cartaoCnpjId: yup
        .number()
        .positive()
        .nullable()
        .label("Cartão do CNPJ (emissão máxima de 30 dias)"),
      certNegInssId: yup
        .number()
        .positive()
        .nullable()
        .label("Certidão negativa de débito expedida pelo INSS"),
      certRegFgtsId: yup
        .number()
        .positive()
        .nullable()
        .label("Certificado de regularidade do FGTS"),
      certNegDebtTrabId: yup
        .number()
        .positive()
        .nullable()
        .label("Certidão negativa de débitos trabalhistas"),
      certNegDebtEstId: yup
        .number()
        .positive()
        .nullable()
        .label("Certidão negativa de débitos estaduais"),
      pendenciasSanadas: yup.boolean().label("Pendências sanadas"),
      relatorioId: yup.number().positive().nullable().label("Relatório"),
      outrosDocsField: yup
        .array()
        .label("Arraste outros documentos aqui ou clique"),
    })
    .required();

  const defaultValues = {
    tecnicoId: "",
    preDiagnosticoId: null,
    pendenciasId: null,
    relatorioId: null,
    outrosDocsField: [],
    dreId: null,
    balancoId: null,
    cartaoCnpjId: null,
    certNegInssId: null,
    certRegFgtsId: null,
    certNegDebtTrabId: null,
    certNegDebtEstId: null,
  };

  const useFormRef = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    watch,
    reset,
    getValues,
    setValue,
    formState: { isDirty, isSubmitting },
  } = useFormRef;

  const tecnicoId = watch("tecnicoId");

  const [aprovando, setAprovando] = React.useState([]);
  const [confirmacaoMsg, setConfirmacaoMsg] = React.useState([]);

  const verificaPreenchimento = () => {
    let erros = [];
    if (!getValues("preDiagnosticoId"))
      erros.push("Inclusão do pré-diagnostico");

    if (
      !getValues("dreId") ||
      !getValues("balancoId") ||
      !visitaTecnica?.docsTecnicosId ||
      !visitaTecnica?.faturasEnergiaId ||
      !visitaTecnica?.faturasEnergeticosId ||
      !visitaTecnica?.faturasEnergia.arquivos.length === 12 ||
      !getValues("pendenciasSanadas")
    )
      erros.push("Sanar pendências informadas");
    if (!getValues("relatorioId")) erros.push("Inclusão de relatório");
    if (erros.length > 0)
      return (
        <React.Fragment>
          <DialogContentText>
            Ainda não foram realizadas as seguintes ações:
          </DialogContentText>
          <ul>
            {erros.map((erro, index) => (
              <li key={index}>
                <DialogContentText>{erro}</DialogContentText>
              </li>
            ))}
          </ul>
        </React.Fragment>
      );
    return "";
  };

  const aprova = (data) => {
    setAprovando(true);
    setConfirmacaoMsg(
      <React.Fragment>
        {verificaPreenchimento()}
        <DialogContentText>
          Esta ação não pode ser desfeita. Tem certeza que deseja <b>aprovar</b>{" "}
          para o diagnóstico energético?
        </DialogContentText>
      </React.Fragment>
    );
    setOpenConfirmacao(true);
  };

  const recusa = () => {
    setAprovando(false);
    setConfirmacaoMsg(
      <React.Fragment>
        Esta ação não pode ser desfeita. Tem certeza que deseja <b>reprovar</b>{" "}
        para diagnóstico energético?
      </React.Fragment>
    );
    setOpenConfirmacao(true);
  };

  const desfazerRecusa = () => {
    setOpenConfirmaDesfRecusa(true);
  };

  const salva = async (data) => {
    startLoading();
    try {
      let retorno;
      if (data.id) {
        retorno = (await api.http.put("/visitas_tecnicas/" + data.id, data))
          .data;
      } else {
        retorno = (
          await api.http.post("/visitas_tecnicas", {
            ...data,
            processoId: currProcesso.id,
          })
        ).data;
      }

      stopLoading({ severity: "success" });
      reset(retorno.visitaTecnica);
      setVisitaTecnica(retorno.visitaTecnica);
      selectProcesso(retorno.processo);
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  const cancela = () => {
    reset();
  };

  const [openConfirmacao, setOpenConfirmacao] = React.useState(false);

  const onSimConfirma = async (data) => {
    setOpenConfirmacao(false);
    const confirmaData = {
      ...data,
      estagio: aprovando ? "preDiagAprovado" : "preDiagReprovado",
    };
    startLoading();
    try {
      let post = (
        await api.http.put(
          "/visitas_tecnicas/" + visitaTecnica.id,
          confirmaData
        )
      ).data;

      selectProcesso(post.processo);
      setVisitaTecnica(post.visitaTecnica); //TODO algo se perdendo por aqui que a tela atualiza o processo mas perde o pré-diagnóstico carregado até o próximo rebuild
      reset(post.visitaTecnica);
      stopLoading({ severity: "success" });
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };
  const onNaoConfirma = () => {
    setOpenConfirmacao(false);
  };

  const [openConfirmaDesfRecusa, setOpenConfirmaDesfRecusa] =
    React.useState(false);

  const onConfirmaDesfRecusa = async (data) => {
    setOpenConfirmaDesfRecusa(false);
    const confirmaData = {
      estagio: "preDiagIniciado",
    };
    startLoading();
    try {
      let put = (
        await api.http.put(
          "/visitas_tecnicas/" + visitaTecnica.id,
          confirmaData
        )
      ).data;
      selectProcesso(put.processo);
      reset(put.visitaTecnica);
      setVisitaTecnica(put.visitaTecnica);
      stopLoading({ severity: "success" });
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  const onNaoConfirmaDesfRecusa = () => {
    setOpenConfirmaDesfRecusa(false);
  };

  const disabledAlocacao = () => {
    return (
      (user.roleId != "superAdmin" &&
        !["preDiagIniciado", "inicioAprovado"].includes(
          currProcesso.estagio
        )) ||
      !["superAdmin", "gestorSENAI"].includes(user.roleId)
    );
  };

  const disabled = () => {
    return (
      (user.roleId != "superAdmin" &&
        !["preDiagIniciado", "inicioAprovado"].includes(
          currProcesso.estagio
        )) ||
      (user.roleId == "consultorEE" && tecnicoId != user.id) ||
      ![
        "consultorEE",
        "gestorSENAI",
        "superAdmin",
        "validadorIndSENAI",
      ].includes(user.roleId)
    );
  };

  React.useEffect(async () => {
    startLoading();
    try {
      setListaTecnicos(
        (
          await api.http.get("/users", {
            params: {
              roleId: "consultorEE",
              habilitado: true,
              ordenado_alfa: true,
            },
          })
        ).data
      );

      if (currProcesso.estagio != "inicioAprovado") {
        let visitaTecnicaData = (
          await api.http.get("/visitas_tecnicas/" + currProcesso.id)
        ).data;
        reset(visitaTecnicaData);
        setVisitaTecnica(visitaTecnicaData);
      }
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  }, []);

  const actionButtons = () => {
    if (
      isDirty ||
      !visitaTecnica ||
      !["preDiagIniciado", "preDiagReprovado"].includes(currProcesso.estagio)
    ) {
      return (
        <React.Fragment>
          <Button
            variant="outlined"
            size="large"
            sx={{ mr: 2 }}
            onClick={cancela}
            disabled={!isDirty || isSubmitting}
          >
            CANCELAR
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={handleSubmit(salva)}
            disabled={!isDirty || isSubmitting}
          >
            SALVAR
          </Button>
        </React.Fragment>
      );
    } else {
      if (
        user.roleId == "superAdmin" &&
        currProcesso.estagio == "preDiagReprovado"
      ) {
        return (
          <React.Fragment>
            <Button
              variant="outlined"
              size="large"
              sx={{ mr: 2 }}
              onClick={desfazerRecusa}
            >
              DESFAZER PROCESSO RECUSADO
            </Button>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <Button
              variant="outlined"
              size="large"
              sx={{ mr: 2 }}
              onClick={recusa}
            >
              RECUSAR PARA DIAGNÓSTICO
            </Button>
            <Button variant="contained" size="large" onClick={aprova}>
              APROVAR PARA DIAGNÓSTICO
            </Button>
          </React.Fragment>
        );
      }
    }
  };

  const exibeCorpo = useMemo(() => {
    return visitaTecnica?.id && tecnicoId;
  }, [tecnicoId, visitaTecnica]);

  return (
    <Paper
      variant="outlined"
      className="center-box"
      sx={{
        textAlign: "center",
        px: 4,
        pt: 2,
        pb: 3,
        maxWidth: "md",
      }}
    >
      <DialogoConfirmacao
        title="Atenção"
        open={openConfirmacao}
        onSim={onSimConfirma}
        onNao={onNaoConfirma}
        criteriosUrl="https://venidera.sharepoint.com/:w:/s/PotencializEE/Eb1F3i4nSM1Es7g5HS2z6E8BeXYpGFXlxvvTCfhwHRDiZA?e=X3DSq0"
      >
        {confirmacaoMsg}
      </DialogoConfirmacao>
      <DialogoConfirmacaoSimples
        title="Atenção"
        open={openConfirmaDesfRecusa}
        onSim={onConfirmaDesfRecusa}
        onNao={onNaoConfirmaDesfRecusa}
      >
        Tem certeza que deseja desfazer recusa do pré-diagnóstico?
      </DialogoConfirmacaoSimples>
      <form onSubmit={handleSubmit(aprova)}>
        <Divider>
          <h3>Alocação de especialista para visita</h3>
        </Divider>
        <FormControl sx={{ my: 2 }} fullWidth>
          <Select
            field="tecnicoId"
            useFormRef={useFormRef}
            schema={schema}
            options={listaTecnicos}
            getOptionLabel={(option) => option.nome}
            disabled={disabledAlocacao()}
          />
        </FormControl>
        {exibeCorpo && (
          <React.Fragment>
            <Divider>
              <h3>Documentos</h3>
            </Divider>
            <FormControl sx={{ my: 2 }} fullWidth>
              <FileField
                field="preDiagnosticoId"
                disabled={disabled()}
                fileData={visitaTecnica?.preDiagnostico}
                useFormRef={useFormRef}
                schema={schema}
              />
            </FormControl>
            <Divider>
              <h3>Pendências</h3>
            </Divider>
            <FormControl sx={{ mb: 2 }}>
              <Checkbox
                field="pendenciasSanadas"
                disabled={disabled()}
                schema={schema}
                useFormRef={useFormRef}
              />
            </FormControl>
            {(!visitaTecnica?.dreId || !visitaTecnica?.balancoId) && (
              <React.Fragment>
                <h4>Demonstrativos financeiros</h4>
              </React.Fragment>
            )}

            <Grid container spacing={2}>
              {!visitaTecnica?.dreId && (
                <React.Fragment>
                  <Grid item xs={6}>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <FileField
                        field="dreId"
                        disabled={disabled()}
                        fileData={currProcesso?.dre}
                        useFormRef={useFormRef}
                        schema={schema}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              )}
              {!visitaTecnica?.balancoId && (
                <React.Fragment>
                  <Grid item xs={6}>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <FileField
                        field="balancoId"
                        disabled={disabled()}
                        fileData={currProcesso?.balanco}
                        useFormRef={useFormRef}
                        schema={schema}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>

            {!visitaTecnica?.docsTecnicosId && (
              <React.Fragment>
                <h4>Documentos técnicos</h4>
                <FileListField
                  field="docsTecnicos"
                  disabled={disabled()}
                  useFormRef={useFormRef}
                  schema={schema}
                  initialFiles={currProcesso?.docsTecnicos} //TODO mudar esses currProcesso pra pegar da visita tecnica
                />
              </React.Fragment>
            )}
            {(!visitaTecnica?.faturasEnergiaId ||
              visitaTecnica?.faturasEnergia.arquivos.length < 12) && (
              <React.Fragment>
                <h4>Documentos financeiros</h4>
                <FileListField
                  field="faturasEnergia"
                  disabled={disabled()}
                  useFormRef={useFormRef}
                  schema={schema}
                  initialFiles={visitaTecnica?.faturasEnergia}
                />
              </React.Fragment>
            )}
            {!visitaTecnica?.faturasEnergeticosId && (
              <React.Fragment>
                <h4>Faturas dos Energéticos</h4>
                <FileListField
                  field="faturasEnergeticos"
                  disabled={disabled()}
                  useFormRef={useFormRef}
                  schema={schema}
                  initialFiles={currProcesso?.faturasEnergeticos}
                />
              </React.Fragment>
            )}

            <Grid container spacing={2}>
              {!visitaTecnica?.cartaoCnpjId && (
                <React.Fragment>
                  <Grid item xs={4}>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <FileField
                        field="cartaoCnpjId"
                        disabled={disabled()}
                        fileData={currProcesso?.cartaoCnpj}
                        useFormRef={useFormRef}
                        schema={schema}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              )}
              {!visitaTecnica?.certNegInssId && (
                <React.Fragment>
                  <Grid item xs={4}>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <FileField
                        field="certNegInssId"
                        disabled={disabled()}
                        fileData={currProcesso?.certNegInss}
                        useFormRef={useFormRef}
                        schema={schema}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              )}
              {!visitaTecnica?.certRegFgtsId && (
                <React.Fragment>
                  <Grid item xs={4}>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <FileField
                        field="certRegFgtsId"
                        disabled={disabled()}
                        fileData={currProcesso?.certRegFgts}
                        useFormRef={useFormRef}
                        schema={schema}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>

            <Grid container spacing={2} marginBottom={4}>
              {!visitaTecnica?.certNegDebtTrabId && (
                <React.Fragment>
                  <Grid item xs={6}>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <FileField
                        field="certNegDebtTrabId"
                        disabled={disabled()}
                        fileData={currProcesso?.certNegDebtTrab}
                        useFormRef={useFormRef}
                        schema={schema}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              )}
              {!visitaTecnica?.certNegDebtEstId && (
                <React.Fragment>
                  <Grid item xs={6}>
                    <FormControl sx={{ my: 2 }} fullWidth>
                      <FileField
                        field="certNegDebtEstId"
                        disabled={disabled()}
                        fileData={currProcesso?.certNegDebtEst}
                        useFormRef={useFormRef}
                        schema={schema}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>

            <Divider>
              <h3>Relatório e Outros Documentos</h3>
            </Divider>

            <FormControl sx={{ mb: 2 }} fullWidth>
              <FileField
                field="relatorioId"
                disabled={disabled()}
                fileData={visitaTecnica?.relatorio}
                useFormRef={useFormRef}
                schema={schema}
              />
            </FormControl>

            <FileListField
              field="outrosDocs"
              disabled={disabled()}
              useFormRef={useFormRef}
              schema={schema}
              initialFiles={visitaTecnica?.outrosDocs}
            />

            {currProcesso && currProcesso.estagio != "preDiagIniciado" && (
              <React.Fragment>
                <Divider>
                  <h3>
                    Observações de{" "}
                    {currProcesso.estagio == "preDiagReprovado"
                      ? "reprovação"
                      : "aprovação"}
                  </h3>
                </Divider>
                <FormControl sx={{ my: 2 }} fullWidth>
                  <TextField
                    value={visitaTecnica?.observacoes}
                    multiline
                    rows={4}
                    disabled={true}
                  />
                </FormControl>
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        <Timestamps dados={visitaTecnica} />

        {!disabled() && (
          <Box sx={{ mt: 3, textAlign: "center" }}>{actionButtons()}</Box>
        )}
      </form>
    </Paper>
  );
};

export default VisitaTecnica;

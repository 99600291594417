import * as React from "react";
// theme
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import PortraitIcon from "@mui/icons-material/Portrait";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewListIcon from "@mui/icons-material/ViewList";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Admin from "./pages/admin/Admin";
import Benchmarking from "./pages/benchmarking/Benchmarking";
import ConfirmaUsuario from "./pages/confirma-usuario/ConfirmaUsuario";
import EnviaOrcamento from "./pages/envia-orcamento/EnviaOrcamento";
import EsqueciMinhaSenha from "./pages/esqueci-minha-senha/EsqueciMinhaSenha";
import Fornecedores from "./pages/fornecedores/Fornecedores";
import Indicadores from "./pages/indicadores/Indicadores";
import InstFinanceiras from "./pages/inst_financeiras/InstFinanceiras";
import Interesse from "./pages/interesse/Interesse";
import Login from "./pages/login/Login";
import NovoProcesso from "./pages/novo_processo/NovoProcesso";
import Perfil from "./pages/perfil/Perfil";
import DialogoHistorico from "./pages/processo/DialogoHistorico";
import Processo from "./pages/processo/Processo";
import Processos from "./pages/processos/Processos";
import api from "./services/api";
import ThemeConfig from "./theme";

function App() {
  const [firstLoad, setFirstLoad] = React.useState(false);
  const [pages, setPages] = React.useState([]);
  let user = useStoreState((state) => state.auth.user);
  let setUser = useStoreActions((actions) => actions.auth.setUser);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    var _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src =
      "https://cdn.matomo.cloud/programapotencializee.matomo.cloud/container_z5e2NOAz.js";
    s.parentNode.insertBefore(g, s);
  }, []);

  React.useEffect(() => {
    if (!user) {
      setPages([]);
      return;
    }

    let newPages = [
      {
        label: "Processos",
        link: "/processos",
      },
    ];

    if (!["parceiro", "desenvolveSP", "instFin"].includes(user.roleId)) {
      newPages = [
        ...newPages,
        {
          label: "Fornecedores",
          link: "/fornecedores",
        },
      ];
    }

    if (
      !["parceiro", "consultorEE", "validadorIndSENAI", "gestorSENAI"].includes(
        user.roleId
      )
    ) {
      newPages = [
        ...newPages,
        {
          label: "Inst. Financeiras",
          link: "/inst_financeiras",
        },
      ];
    }

    if (user.roleId != "validadorIndSENAI") {
      newPages = [
        ...newPages,
        {
          label: "Relatórios",
          link: "/indicadores",
        },
      ];
    }

    newPages = [
      ...newPages,
      {
        label: "Benchmarking",
        link: "/benchmarking",
      },
    ];
    setPages(newPages);
  }, [user]);

  const Sistema = () => {
    return (
      <div>
        <AppBar
          position="static"
          sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
        >
          <Container maxWidth="x1">
            <Stack
              spacing={2}
              direction="row"
              justifyContent="center"
              sx={{ p: 1 }}
            >
              {pages.map((page) => (
                <Button
                  color="inherit"
                  key={page.link}
                  variant={
                    location.pathname != page.link ? "text" : "contained"
                  }
                  onClick={() => navigate(page.link)}
                >
                  {page.label}
                </Button>
              ))}
            </Stack>
          </Container>
        </AppBar>
        <Outlet />
      </div>
    );
  };

  const Layout = () => {
    let loading = useStoreState((state) => state.nav.loading);
    let openMessage = useStoreState((state) => state.nav.openMessage);
    let autoHideDuration = useStoreState((state) => state.nav.autoHideDuration);
    let message = useStoreState((state) => state.nav.message);
    let severity = useStoreState((state) => state.nav.severity);
    let closeMessage = useStoreActions((actions) => actions.nav.closeMessage);

    const handleErrorClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      closeMessage();
    };

    // responsive
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };

    return (
      <ThemeConfig>
        {loading > 0 && <LinearProgress />}
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={2} sx={{ textAlign: "center" }}>
            {user && (
              <Box sx={{ display: { xs: "inline-block", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="menu"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="secondary"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem
                      key={page.link}
                      onClick={() => navigate(page.link)}
                      selected={location.pathname != page.link ? false : true}
                    >
                      <Typography textAlign="center">{page.label}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
          </Grid>
          <Grid item sx={{ flexGrow: 1, textAlign: "center" }} xs={8}>
            <Box
              component="img"
              src="/logo-potencializee.png"
              sx={{ maxWidth: "100%" }}
            />
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "right" }}>
            <AuthStatus />
          </Grid>
        </Grid>

        <Container>
          <Outlet />
        </Container>

        <Snackbar
          open={openMessage}
          autoHideDuration={autoHideDuration}
          onClose={handleErrorClose}
        >
          <Alert
            onClose={handleErrorClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </ThemeConfig>
    );
  };

  const RequireAuth = ({ children }) => {
    let user = useStoreState((state) => state.auth.user);
    let location = useLocation();

    if (!user) {
      //   // Redirect them to the /login page, but save the current location they were
      //   // trying to go to when they were redirected. This allows us to send them
      //   // along to that page after they login, which is a nicer user experience
      //   // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
  };

  React.useEffect(async () => {
    try {
      setUser((await api.http.get("auth-user")).data);
    } catch (error) {
      // do nothing
    }
    setFirstLoad(true);
  }, []);

  if (firstLoad) {
    return (
      <Routes>
        <Route element={<Layout />}>
          <Route path="interesse" element={<Interesse />} />
          <Route path="novo_processo" element={<NovoProcesso />} />
          <Route path="login" element={<Login />} />
          <Route path="/esqueci-minha-senha" element={<EsqueciMinhaSenha />} />
          <Route path="/confirma-usuario/:hash" element={<ConfirmaUsuario />} />
          <Route path="/envia-orcamento/:hash" element={<EnviaOrcamento />} />
          <Route
            element={
              <RequireAuth>
                <Sistema />
              </RequireAuth>
            }
          >
            <Route path="processos" element={<Processos />} />
            <Route path="processo" element={<Processo />} />
            <Route path="processo/:id" element={<Processo />} />
            <Route path="fornecedores" element={<Fornecedores />} />
            <Route path="inst_financeiras" element={<InstFinanceiras />} />
            <Route path="indicadores" element={<Indicadores />} />
            <Route path="benchmarking" element={<Benchmarking />} />
            <Route path="/perfil" element={<Perfil />} />
            <Route path="/admin" element={<Admin />} />
          </Route>
          <Route path="*" element={<Navigate to="interesse" replace />} />
        </Route>
      </Routes>
    );
  } else {
    return <LinearProgress />;
  }
}

const AuthStatus = () => {
  let setUser = useStoreActions((actions) => actions.auth.setUser);
  let user = useStoreState((state) => state.auth.user);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openHistorico, setOpenHistorico] = React.useState(false);

  const onCloseHistorico = () => {
    setOpenHistorico(false);
  };

  const navigate = useNavigate();
  if (!user) {
    if (location.pathname != "/login") {
      return (
        <Link
          onClick={() => {
            navigate("/login");
          }}
        >
          Login
        </Link>
      );
    }
    return "";
  }

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const sair = async () => {
    setAnchorEl(null);
    await api.signout();
    navigate("/login");
    setUser(null);
  };

  return (
    <React.Fragment>
      <Tooltip title="Configurações da conta">
        <IconButton
          onClick={handleClick}
          size="large"
          color="secondary"
          sx={{ mr: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <AccountCircleIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        id="account-menu"
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,

            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 23,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {user.roleId == "superAdmin" && (
          <MenuItem
            onClick={() => {
              setOpenHistorico(true);
            }}
          >
            <ListItemIcon>
              <ViewListIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Histórico</ListItemText>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            navigate("/perfil");
          }}
        >
          <ListItemIcon>
            <PortraitIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Meu perfil</ListItemText>
        </MenuItem>
        {user.roleId == "superAdmin" && (
          <MenuItem
            onClick={() => {
              navigate("/admin");
            }}
          >
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Administrar</ListItemText>
          </MenuItem>
        )}
        <Divider />
        <MenuItem onClick={sair}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Sair</ListItemText>
        </MenuItem>
      </Menu>
      <DialogoHistorico open={openHistorico} onClose={onCloseHistorico} />
    </React.Fragment>
  );
};

export default App;

export const categoriasTecnologia = {
  A: 'Intervenções Térmicas',
  B: 'Controles e intervenções de gerenciamento de energia',
  C: 'Intervenções elétricas'
};

export const listaCategoriasTecnologia = [{
  id: 1,
  categoria: 'A',
  label: 'Isolamento térmico'
},{
  id: 2,
  categoria: 'A',
  label: 'Instalações e sistemas de caldeiras a vapor'
},{
  id: 3,
  categoria: 'A',
  label: 'Economizadores de condensação e gases de combustão'
},{
  id: 4,
  categoria: 'A',
  label: 'Recuperação de calor de ar'
},{
  id: 5,
  categoria: 'A',
  label: 'Recuperação de calor por refrigeração para geração de água quente'
},{
  id: 6,
  categoria: 'A',
  label: 'Desperdício de calor para eletricidade'
},{
  id: 7,
  categoria: 'A',
  label: 'Aquecimento / Processo de calor em geral'
},{
  id: 8,
  categoria: 'A',
  label: 'Solar térmico'
},{
  id: 9,
  categoria: 'A',
  label: 'Bombas de calor (fonte de ar, fonte de solo e fonte de água)'
},{
  id: 10,
  categoria: 'A',
  label: 'Caldeira de biomassa'
},{
  id: 11,
  categoria: 'A',
  label: 'Calor e energia combinados (CHP) (convencional, célula de combustível ou biomassa, excluindo carvão)'
},{
  id: 12,
  categoria: 'A',
  label: 'Elementos de construção e intervenções térmicas passivas'
},{
  id: 13,
  categoria: 'B',
  label: 'Sistema de Gestão Predial (SGP) e controles de processo'
},{
  id: 14,
  categoria: 'B',
  label: 'Sistemas de Gestão de Energia (SGE)'
},{
  id: 15,
  categoria: 'B',
  label: 'Submedição e monitoramento de energia'
},{
  id: 16,
  categoria: 'B',
  label: 'Consolidação da oferta'
},{
  id: 17,
  categoria: 'C',
  label: 'Iluminação'
},{
  id: 18,
  categoria: 'C',
  label: 'Motores e acionamentos'
},{
  id: 19,
  categoria: 'C',
  label: 'Refrigeração e ar-condicionado'
},{
  id: 20,
  categoria: 'C',
  label: 'Ventilação'
},{
  id: 21,
  categoria: 'C',
  label: 'Ar comprimido'
},{
  id: 22,
  categoria: 'C',
  label: 'Transformadores'
},{
  id: 23,
  categoria: 'C',
  label: 'Redução / otimização de tensão'
},{
  id: 24,
  categoria: 'C',
  label: 'Sistemas de bombeamento'
}];

export const opcoesBooleanas = [{
  id: 1,
  label: 'SIM'
},{
  id: 2,
  label: 'NÃO'
}];

export const regioes = [{
  id:1,
  label: 'Polo 1: São Paulo e Baixada Santista',
},{
  id:2,
  label: 'Polo 2: Campinas',
},{
  id:3,
  label: 'Polo 3: Sorocaba, Itapeva e Registro',
},{
  id:4,
  label: 'Polo 4 São José dos Campos',
},{
  id:5,
  label: 'Polo 5: Central',
},{
  id:6,
  label: 'Polo 6: Ribeirão Preto, Barretos e Franca',
},{
  id:7,
  label: 'Polo 7: São José do Rio Preto e Araçatuba',
},{
  id:8,
  label: 'Polo 8: Marília',
},{
  id:9,
  label: 'Polo 9: Bauru',
},{
  id:10,
  label: 'Polo 10: Presidente Prudente',
},{
  id:11,
  label: 'Outro'
}];

export const listaEnergeticos = [{
  id:1,
  label: 'Energia elétrica',
},{
  id:2,
  label: 'Lenha',
},{
  id:3,
  label: 'Óleo combustível',
},{
  id:4,
  label: 'Óleo diesel',
},{
  id:5,
  label: 'Gás natural',
},{
  id:6,
  label: 'GLP',
},{
  id:7,
  label: 'Carvão vegetal',
},{
  id:8,
  label: 'Etanol',
},{
  id:9,
  label: 'Bagaço de cana',
},{
  id:10,
  label: 'Liquor negro',
},{
  id:11,
  label: 'Coque',
}];

export const subsegmentos = {
  // Têxtil e vestuário
  1: [{
    id: 1,
    label: 'Fiação'
  },{
    id: 2,
    label: 'Tecelagem'
  },{
    id: 3,
    label: 'Malharia'
  },{
    id: 4,
    label: 'Beneficiamento'
  },{
    id: 5,
    label: 'Confecção'
  }],
  // Cerâmica, vidro e produtos não metálicos
  2: [{
    id: 6,
    label: 'Produção de cimento'
  },{
    id: 7,
    label: 'Produção de cerâmica branca'
  },{
    id: 8,
    label: 'Produção de cerâmica vermelha'
  },{
    id: 9,
    label: 'Produção de vidro'
  },{
    id: 69,
    label: 'Produção de artefatos de concreto, cimento, fibrocimento, gesso e materiais semelhantes'
  },{
    id: 70,
    label: 'Aparelhamento de pedras e fabricação de outros produtos minerais não-metálicos.'
  }],
  // Metalurgia e produtos metálicos
  3: [{
    id: 10,
    label: 'Produção de esquadrias de metal'
  },{
    id: 11,
    label: 'Fundição de ferro e aço'
  },{
    id: 12,
    label: 'Produção de embalagens metálicas'
  },{
    id: 13,
    label: 'Produção de artefatos estampados de metal'
  },{
    id: 14,
    label: 'Fundição de metais não ferrosos e suas ligas'
  },{
    id: 15,
    label: 'Produção de outros produtos de metal não especificados anteriormente'
  },{
    id: 16,
    label: 'Produção de obras de caldeiraria pesada'
  },{
    id: 17,
    label: 'Produção de estruturas metálicas'
  },{
    id: 18,
    label: 'Produção de artigos de metal para uso doméstico e pessoal'
  },{
    id: 19,
    label: 'Produção de ferramentas'
  },{
    id: 20,
    label: 'Serviço de usinagem, torneiria e solda'
  },{
    id: 21,
    label: 'Produção de produtos de trefilados de metal, exceto padronizados'
  },{
    id: 22,
    label: 'Produção de artigos de serralheria, exceto esquadrias'
  },{
    id: 23,
    label: 'Produção de tanques, reservatórios metálicos e caldeiras para aquecimento central'
  },{
    id: 24,
    label: 'Serviços de tratamento e revestimento em metais'
  }],
  // Transformados plásticos
  4: [{
    id: 25,
    label: 'Produção de artefatos de material plástico para uso pessoal e doméstico'
  },{
    id: 26,
    label: 'Produção de artefatos de material plástico para usos industriais'
  },{
    id: 27,
    label: 'Produção de artefatos de material plástico para outros usos não especificados anteriormente'
  },{
    id: 28,
    label: 'Produção de embalagens de material plástico'
  },{
    id: 29,
    label: 'Produção de artefatos de material plástico para uso na construção, exceto tubos e acessórios'
  },{
    id: 30,
    label: 'Reforma de pneumáticos usados'
  },{
    id: 31,
    label: 'Produção de tubos e acessórios de material plástico para uso na construção'
  },{
    id: 32,
    label: 'Produção de laminados planos e tubulares de material plástico'
  },{
    id: 33,
    label: 'Produção de artefatos de borracha não especificados  anteriormente'
  }],
  // Alimentos e bebidas
  5:[{
    id: 34,
    label: 'Bebidas'
  },{
    id: 35,
    label: 'Carnes (abate)'
  },{
    id: 36,
    label: 'Carne (industrialização)'
  },{
    id: 37,
    label: 'Leite (pasteurizado e UHT) e laticínios'
  },{
    id: 38,
    label: 'Rações e alimentos para animais'
  },{
    id: 39,
    label: 'Cacau, chocolate e doces'
  },{
    id: 40,
    label: 'Soja'
  },{
    id: 41,
    label: 'Óleo vegetal (refino)'
  },{
    id: 42,
    label: 'Trigo'
  },{
    id: 43,
    label: 'Arroz'
  },{
    id: 44,
    label: 'Milho'
  },{
    id: 45,
    label: 'Café'
  },{
    id: 46,
    label: 'Pescados'
  },{
    id: 47,
    label: 'Suco de laranja'
  },{
    id: 66,
    label: 'Produção de conservas de frutas, legumes e outros vegetais'
  },{
    id: 67,
    label: 'Produção e refino de açúcar'
  }],
  // Papel e celulose
  6: [{
    id: 48,
    label: 'Produção de papel'
  },{
    id: 49,
    label: 'Produção de celulose'
  },{
    id: 50,
    label: 'Produção de papel reciclado'
  },{
    id: 68,
    label: 'Produção de embalagens de papel, cartolina, papel-cartão, e papelão ondulado'
  }],
  // Química e farmacêutica
  7: [{
    id: 51,
    label: 'Sabões, detergentes, produtos de limpeza, cosméticos, de perfumaria e higiene pessoal'
  },{
    id: 52,
    label: 'Produtos químicos orgânicos'
  },{
    id: 53,
    label: 'Resinas e elastômetros'
  },{
    id: 54,
    label: 'Fibras artificiais e sintéticas'
  },{
    id: 55,
    label: 'Defensivos agrícolas e desinfetantes domissanitários'
  },{
    id: 56,
    label: 'Produtos químicos inorgânicos'
  },{
    id: 57,
    label: 'Tintas, vernizes, esmalte, lacas e produtos afins'
  },{
    id: 58,
    label: 'Produtos e preparados químicos diversos'
  },{
    id: 59,
    label: 'Produtos farmoquímicos'
  },{
    id: 60,
    label: 'Produtos farmacêuticos'
  }],
  // Automóveis e peças
  8: [{
    id: 61,
    label: 'Produção de automóveis, caminhonetas e utilitários'
  },{
    id: 62,
    label: 'Produção de caminões e ônibus'
  },{
    id: 63,
    label: 'Produção de cabines, carrocerias e reboques para veículos automotores'
  },{
    id: 64,
    label: 'Produção de peças e acessórios para veículos automotores'
  },{
    id: 65,
    label: 'Recondicionamento e recuperaç~ao para veículos automotores'
  }],
  // Outros
  9: [{
    id: 66,
    label: 'Outros'
  }],
};

export const listaProcessos = [{
  id: 1,
  label: 'Linha de Produção'
},{
  id: 2,
  label: 'Processos Auxiliares'
}];

export const sistemas = {
  1: [{
    id: 1,
    label: 'Sistemas Térmicos - Caldeiras'
  },{
    id: 10,
    label: 'Sistemas Térmicos - Trocadores de calor'
  },{
    id: 11,
    label: 'Sistemas Térmicos - Bombas de calor'
  },{
    id: 12,
    label: 'Sistemas Térmicos - Aquecedores'
  },{
    id: 13,
    label: 'Sistemas Térmicos - Fornos e Fornalhas'
  },{
    id: 14,
    label: 'Sistemas Térmicos - Radiação térmica'
  },{
    id: 15,
    label: 'Sistemas Térmicos - Sistemas motrizes estacionários a combustão'
  },{
    id: 16,
    label: 'Sistemas Térmicos - Motores de veículos de transporte'
  },{
    id: 17,
    label: 'Sistemas Térmicos - Tratores'
  },{
    id: 2,
    label: 'Sistemas Motrizes',
  },{
    id: 3,
    label: 'Sistemas de Distribuição'
  },{
    id: 7,
    label: 'Processos Eletroquímicos'
  },{
    id: 8,
    label: 'Sistemas de Refrigeração'
  }],
  2: [{
    id: 4,
    label: 'Sistemas de Ar Comprimido',
  },{
    id: 5,
    label: 'Sistemas de Iluminação',
  },{
    id: 6,
    label: 'Sistemas de Condicionamento Ambiental'
  },{
    id: 9,
    label: 'Sistemas de Bombeamento'
  }]
};

export const listaComoSoubePrograma = [{
  id: 1,
  label:'Redes sociais'
},{
  id: 2,
  label:'Associação Setorial'
},{
  id: 3,
  label:'Contato direto de ESCO'
},{
  id: 4,
  label:'Contato direto de Fornecedor de Tecnologia'
},{
  id: 5,
  label:'Anúncios'
},{
  id: 6,
  label:'Sindicato'
},{
  id: 7,
  label:'Imprensa'
},{
  id: 8,
  label:'Palestras'
},{
  id: 9,
  label:'Contato direto do SENAI'
},{
  id: 10,
  label:'E-mail marketing'
},{
  id: 11,
  label:'Outros'
}];

export const operaFeriados = [
  {
    id: 1,
    label: "Sim"
  },
  {
    id: 2,
    label: "Não"
  }
]

export const textoExAnte = "Esta fase refere-se ao desenvolvimento do diagnóstico energético, onde as economias de energia são estimadas a partir de cálculos de engenharia para avaliação da energia consumida no sistema atual e para a energia que será consumida a partir do sistema proposto";

export const textoExPost = "Esta fase refere-se à execução do projeto, onde as economias de energia são estimadas a partir de medições em campo da energia consumida no sistema atual, antes da substituição, e das medições em capo da energia consumida pelo novo sistema mais eficiente, após substituição e execução da obra.";


export const tagsRel = {
  headers: [
    { key: "titulo", label: "Título"},
    { key: "ref_1_1", label: "Número de diagnósticos energéticos iniciados"},
    { key: "ref_1_2", label: "Número de diagnósticos energéticos concluídos"},
    // { key: "ref_1_3", label: "Valor agregado do subsídio para auditorias energéticas em EUR"},
    { key: "ref_1_4", label: "Número de projetos de EE submetidos ao GCI"},
    { key: "ref_1_5", label: "Número de projetos de EE com suporte à implementação"},
    // { key: ref_1_6", label: "Montante de subsídio agregado para apoio à implementação em EUR"},
    { key: "ref_1_7", label: "Número de PMEs com suporte à implementação concluído"},
    // { key: ref_1_8", label: "Custo agregado de auditoria energética incorrido por PMEs em reais"},
    // { key: ref_1_9", label: "Custo agregado de suporte de implementação incorrido por PMEs em reais"},
    // { key: ref_1_10", label: "Custo médio das auditorias energéticas em reais"},
    // { key: ref_1_11", label: "Custo médio de suporte de implementação em BRL"},
    { key: "ref_1_12", label: "Tamanho médio do tícket de projetos de EE em EUR"},
    { key: "ref_1_13", label: "Custos de investimento agregados esperados em EUR"},
    { key: "ref_1_14", label: "Valor esperado de redução de emissões de gases de efeito estufa (tCO2 cumulativo-vida útil)"},
    { key: "ref_1_15", label: "Valor esperado de economia com EE (KWh cumulativo-vida útil)"},
    { key: "ref_1_16", label: "Número agregado de funcionários do sexo feminino beneficiadas por diagnósticos energéticos"},
    { key: "ref_1_17", label: "Número agregado de funcionários do sexo masculino beneficiados por diagnósticos energéticos"},
    { key: "ref_1_18", label: "Número agregado de funcionários do sexo feminino beneficiadas pelo suporte à implementação"},
    { key: "ref_1_19", label: "Número agregado de funcionários do sexo masculino beneficiados pelo suporte à implementação"},

    { key: "ref_2_1", label: "Descrição do projeto de EE"},
    { key: "ref_2_2", label: "Diagnóstico energético iniciado"},
    // { key: ref_2_3", label: "Montante de subsídio para auditorias energéticas"},
    // { key: ref_2_4", label: "Custo da auditoria energética para PME em reais"},
    { key: "ref_2_5", label: "Diagnóstico energético concluído"},
    { key: "ref_2_6", label: "Projeto encaminhado ao GCI"},
    { key: "ref_2_7", label: "Projeto de EE com suporte de implementação"},
    // { key: ref_2_8", label: "Montante de subsídio para apoio à implementação"},
    // { key: ref_2_9", label: "Custos de suporte de implementação para SME em BRL"},
    { key: "ref_2_10", label: "Suporte de implementação concluído"},
    { key: "ref_2_11", label: "Categorias tecnológicas utilizadas"},
    { key: "ref_2_12", label: "É necessária uma avaliação de impacto ambiental?"},
    { key: "ref_2_13", label: "Custo total de investimento em EE esperado em EUR"},
    // { key: ref_2_14", label: "Taxa de câmbio relevante"},
    { key: "ref_2_15", label: "Localização geográfica"},
    { key: "ref_2_16", label: "Período de implementação planejado"},
    { key: "ref_2_17", label: "Estimativa de data de conclusão"},
    { key: "ref_2_18", label: "Empregos criados no período de implementação do projeto de EE"},
    { key: "ref_2_19", label: "Valor de investimento de fontes privadas"},

    { key: "ref_3_1", label: "Investimento Elegível para EE de acordo com o programa PotencializEE?"},
    { key: "ref_3_2", label: "Valor esperado de economia com EE (KWh cumulativo-vida útil)"},
    { key: "ref_3_3", label: "Porcentagem esperada de economia com EE (%)"},
    { key: "ref_3_4", label: "Valor esperado de redução de emissões de gases de efeito estufa (t de CO2 cumulativo-vida útil)"},
    { key: "ref_3_5", label: "Porcentagem esperada de redução de emissões de gases de 'efeito estufa (%)"},
    { key: "ref_3_6", label: "VPL da economia esperada de EE em relação à vida útil do investimento em EE (R$)"},
    { key: "ref_3_7", label: "50% do custo de investimento em EE coberto pelo VPL das economias de EE"},
    { key: "ref_3_8", label: "Investimento em EE concluído"},
    { key: "ref_3_9", label: "Custo total do investimento em EE (R$)"},
    { key: "ref_3_10", label: "Valor verificado de economia com EE (KWh cumulativo-vida útil)"},
    { key: "ref_3_11", label: "Porcentagem de economia com EE  verificado (%)"},
    { key: "ref_3_12", label: "Valor de redução de emissões de gases de efeituo estufa verificado (tde CO2 cumulativo-vida útil)"},
    { key: "ref_3_13", label: "Porcentagem de redução de emissões de gases de efeito estufa verificado (%)"},

    { key: "ref_4_1", label: "Nome civil do Beneficiário Final"},
    { key: "ref_4_2", label: "CNPJ do Beneficiário Final"},
    { key: "ref_4_3", label: "Localização geográfica"},
    { key: "ref_4_4", label: "Categoria do Beneficiário Final"},
    { key: "ref_4_5", label: "Cidade"},
    { key: "ref_4_6", label: "Código postal"},
    { key: "ref_4_7", label: "Endereço"},
    { key: "ref_4_9", label: "Número de funcionários total da PME"},
    { key: "ref_4_10", label: "Segmento industrial"},
    { key: "ref_4_11", label: "Número de funcionárias beneficiadas por diagnósticos energéticos"},
    { key: "ref_4_12", label: "Número de funcionários do sexo masculino beneficiados de diagnósticos energéticos"},
    { key: "ref_4_13", label: "Número agregado de funcionários do sexo feminino beneficiadas pelo suporte à implementação"},
    { key: "ref_4_14", label: "Número agregado de funcionários do sexo masculino beneficiadas pelo suporte à implementação"},
  ],

  subHeaders: {
    titulo: "Referência",
    ref_1_1: "1.1",
    ref_1_2: "1.2",
    // ref_1_3: "1.3",
    ref_1_4: "1.4",
    ref_1_5: "1.5",
    // ref_1_6: "1.6",
    ref_1_7: "1.7",
    // ref_1_8: "1.8",
    // ref_1_9: "1.9",
    // ref_1_10: "1.10",
    // ref_1_11: "1.11",
    ref_1_12: "1.12",
    ref_1_13: "1.13",
    ref_1_14: "1.14",
    ref_1_15: "1.15",
    ref_1_16: "1.16",
    ref_1_17: "1.17",
    ref_1_18: "1.18",
    ref_1_19: "1.19",

    ref_2_1: "2.1",
    ref_2_2: "2.2",
    // ref_2_3: "2.3",
    // ref_2_4: "2.4",
    ref_2_5: "2.5",
    ref_2_6: "2.6",
    ref_2_7: "2.7",
    // ref_2_8: "2.8",
    // ref_2_9: "2.9",
    ref_2_10: "2.10",
    ref_2_11: "2.11",
    ref_2_12: "2.12",
    ref_2_13: "2.13",
    // ref_2_14: "2.14",
    ref_2_15: "2.15",
    ref_2_16: "2.16",
    ref_2_17: "2.17",
    ref_2_18: "2.18",
    ref_2_19: "2.19",

    ref_3_1: "3.1",
    ref_3_2: "3.2",
    ref_3_3: "3.3",
    ref_3_4: "3.4",
    ref_3_5: "3.5",
    ref_3_6: "3.6",
    ref_3_7: "3.7",
    ref_3_8: "3.8",
    ref_3_9: "3.9",
    ref_3_10: "3.10",
    ref_3_11: "3.11",
    ref_3_12: "3.12",
    ref_3_13: "3.13",

    ref_4_1: "4.1",
    ref_4_2: "4.2",
    ref_4_3: "4.3",
    ref_4_4: "4.4",
    ref_4_5: "4.5",
    ref_4_6: "4.6",
    ref_4_7: "4.7",
    ref_4_8: "4.8",
    ref_4_9: "4.9",
    ref_4_10: "4.10",
    ref_4_11: "4.11",
    ref_4_12: "4.12",
    ref_4_13: "4.13",
    ref_4_14: "4.14"
  }
};
